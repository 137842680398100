import {User} from '@hconnect/apiclient'
import {Typography, LookupSimpleFilter} from '@hconnect/uikit'
import {makeStyles} from '@material-ui/core'
import {TFunction} from 'i18next'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import React, {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {batch, useDispatch, useSelector} from 'react-redux'

import {PageNames} from '../../common/constants'
import {AppState} from '../../Root.store'
import {filterType, useTrackFilterEvents} from '../../TrackEvents/hubFilterEvents'

import {
  fetchCustomerByCustomerNumber,
  setCustomerSearchCustomer,
  setCustomerSearchString,
  setGlobalCustomerFilter
} from './Customers.action'
import {
  selectCustomers,
  selectSelectedCustomerFilter,
  selectSearchIsChoosen
} from './Customers.selector'
import {Customer} from './Customers.types'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
    height: 58,
    paddingTop: 6
  }
}))

interface SelectItemType {
  customer?: Customer
  onLight?: boolean
  t: TFunction
}

const SelectItem: React.FC<SelectItemType> = ({customer, onLight, t}) => {
  return (
    <>
      <Typography component="div" variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {customer?.customerName || ''}
      </Typography>
      <Typography variant="caption" customColor={onLight ? 'textPrimarySoft' : 'textSecondarySoft'}>
        {t('customers.customerDropdown.label')} {customer?.customerNumber ?? ''}
      </Typography>
    </>
  )
}

interface Props {
  'data-test-id': string
  customerSearchByName?: boolean
  dark?: boolean
  page: PageNames
}
export const CustomerSimpleLookup: React.FC<Props> = ({
  'data-test-id': dataTestId,
  customerSearchByName = false,
  dark = false,
  page
}) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const {trackFilterEvents} = useTrackFilterEvents()
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)

  const customers = useSelector<AppState, Customer[]>((state) =>
    get(selectCustomers(state), 'customers')
  )
  const isFetching = useSelector<AppState, boolean>((state) =>
    get(selectCustomers(state), 'isFetching')
  )
  const selectedCustomer = useSelector<AppState, Customer | null>((state) =>
    selectSelectedCustomerFilter(state)
  )

  const isChoosen = useSelector<AppState, boolean>((state) => selectSearchIsChoosen(state))

  const globalSearchString: string = useSelector<AppState, string>(
    (state) => selectCustomers(state).searchString
  )
  const [searchString, setSearchString] = useState(globalSearchString)

  useEffect(() => {
    setSearchString(globalSearchString)
  }, [globalSearchString])

  useEffect(() => {
    if (selectedCustomer) {
      setSearchString(selectedCustomer.customerName)
    }
  }, [selectedCustomer])

  useEffect(() => {
    if (customerSearchByName && isChoosen && selectedCustomer) {
      setSearchString(selectedCustomer.customerName)
    }
  }, [isChoosen, selectedCustomer])

  const handleChangeDropdown = (item: Customer) => {
    dispatch(setGlobalCustomerFilter(item))
    user?.user_id && localStorage.setItem(`default_customer-${user?.user_id}`, JSON.stringify(item))
    customerSearchByName && dispatch(setCustomerSearchString(item.customerName))
    customerSearchByName && dispatch(setCustomerSearchCustomer())
    trackFilterEvents({
      filterType: filterType.CUSTOMER_SIMPLE_LOOKUP,
      page,
      customerId: item.customerId,
      cleared: !item.customerId
    })
  }

  const handleSearchAgain = (searchTerm: string) => {
    const trimmedSearchTerm = searchTerm.trim()
    if (trimmedSearchTerm !== '') {
      return dispatch(
        fetchCustomerByCustomerNumber(
          trimmedSearchTerm,
          trimmedSearchTerm,
          user?.user_id,
          customerSearchByName
        )
      )
    }
  }

  const debounceHandleASearchAgain = useCallback(
    debounce((newValue) => handleSearchAgain(newValue), 500),
    []
  )

  const updateValue = (newValue: string) => {
    setSearchString(newValue)
    customerSearchByName && dispatch(setCustomerSearchString(newValue))
    customerSearchByName && debounceHandleASearchAgain(newValue)
  }

  return (
    <LookupSimpleFilter
      label={
        customerSearchByName ? t('filterBar.customerByNameLabel') : t('filterBar.customerLabel')
      }
      value={searchString}
      onChange={(e) => updateValue(e.target.value)}
      onSubmit={() =>
        batch(() => {
          const searchInput = searchString.trim()
          if (searchInput) {
            dispatch(setCustomerSearchString(searchString))
            dispatch(
              fetchCustomerByCustomerNumber(
                searchString,
                searchString,
                user?.user_id,
                customerSearchByName
              )
            )
          }
        })
      }
      data-test-id={dataTestId}
      items={isChoosen ? [selectedCustomer] : customers}
      loading={isFetching}
      itemId="customerId"
      selectedItem={selectedCustomer}
      renderItem={(item: Customer) => <SelectItem customer={item} onLight t={t} />}
      onChangeDropdown={handleChangeDropdown}
      customerSearchByName={customerSearchByName}
      searchAgain={debounceHandleASearchAgain}
      dark={dark}
      className={classes.fullWidth}
    />
  )
}
