/* eslint-disable complexity */
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {
  MOMENTJS_DATE_FORMAT,
  prevMonthDates,
  prevQuarterDates,
  thisWeekDates
} from '@hconnect/uikit'
import {push} from 'connected-react-router'
import {get} from 'lodash'
import moment from 'moment'

import {sheetRequest} from '../../../AsyncJobQueue/AsyncJobQueue.action'
import {
  ApiDownloadRequestFormats,
  ApiDownloadRequestTypes
} from '../../../AsyncJobQueue/AsyncJobQueue.types'
import {setGlobalCustomerFilter} from '../../../Organisms/Customers'
import {Customer} from '../../../Organisms/Customers/Customers.types'
import {
  clear as clearInvoices,
  fetchDeliveryByCustomerDeliveryNumber,
  fetchDeliveryByDeliveryNumber,
  fetchInvoices as fetchInvoicesAbstract,
  fetchInvoicesFailure,
  fetchInvoicesRequest,
  selectDeliveriesByDeliveryNumber,
  selectDeliveriesByCustomerDeliveryNumber,
  selectInvoices
} from '../../../Organisms/Invoices'
import {AppState} from '../../../Root.store'

import {
  INVOICES_CLEAR_VIEW,
  INVOICES_SWITCH_VIEW,
  InvoicePageActionTypes,
  InvoiceViews,
  LookupEntityParam,
  LookupSelectItem
} from './Action.types'
import {Delivery, Link, Rel} from './Delivery.types'
import {AccountParams} from './hooks'
import {
  selectCustomerInvoiceFilter,
  selectDateFilter,
  selectLookupFilter,
  selectOpenInvoicesFilter,
  selectProjectFilter,
  selectSiteFilter,
  selectSurchargesOnlyFilter,
  setDateFilter
} from './Invoice.filters'
import {
  BulkDownloadArgs,
  InvoiceDocumentsExportCriteria,
  SearchInvoicesQueryParameters
} from './Invoice.types'

const InvoiceRoute = '/finance/invoices'

export const switchView = ({
  view,
  clearPrevView
}: {
  view: InvoiceViews
  clearPrevView?: boolean
}): InvoicePageActionTypes => ({
  type: INVOICES_SWITCH_VIEW,
  view,
  clearPrevView
})

export const clearCurrentView = (): InvoicePageActionTypes => ({
  type: INVOICES_CLEAR_VIEW
})

export const fetchInvoiceById = (invoiceId: string) => (dispatch) => {
  const params = {
    invoiceId
  }
  dispatch(fetchInvoicesAbstract(params))
}

const getInvoiceIdFromDeliveryLinks = (links: Link[]): string | undefined => {
  const url: string | undefined = get(
    links.find((link: Link) => link.rel === Rel.Invoices),
    'href'
  )
  return url ? url.split('/')[1] : undefined
}

export const fetchInvoices =
  (
    baseParams: AccountParams & {expand?: string},
    isPumpServiceIncluded = false,
    analyticsId?: string
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch, getState, {api}) => {
    const expand = baseParams.expand ? `itemBreakdown,${baseParams.expand}` : 'itemBreakdown'
    const state: AppState = getState()
    const {customerId} = selectCustomerInvoiceFilter(state) || {}
    const {orgUnitIds} = state.finance.invoices.filters

    let params: SearchInvoicesQueryParameters = {
      ...baseParams,
      ...(orgUnitIds ? {orgUnitIds} : {}),
      expand
    }
    const lookup = selectLookupFilter(state)
    if (lookup) {
      const {entity, value} = lookup
      if (
        entity === (LookupSelectItem.BOL as string) ||
        entity === (LookupSelectItem.TICKET as string)
      ) {
        let deliveries: Delivery[] = []
        if (entity === (LookupSelectItem.TICKET as string)) {
          dispatch(fetchInvoicesRequest({deliveryNumber: value, customerId}))
          await dispatch(
            fetchDeliveryByDeliveryNumber(value, customerId, isPumpServiceIncluded, () =>
              trackEvent('hubLookupFailed', {
                product: 'hub',
                lookupCategory: entity,
                lookupValue: value,
                lookupSource: 'invoices',
                customerId,
                analyticsId
              })
            )
          )
          const latestState: AppState = getState()
          deliveries = selectDeliveriesByDeliveryNumber(latestState, value)
        }
        if (entity === (LookupSelectItem.BOL as string)) {
          dispatch(fetchInvoicesRequest({customerDeliveryNumber: value, customerId}))
          await dispatch(
            fetchDeliveryByCustomerDeliveryNumber(value, customerId, isPumpServiceIncluded, () =>
              trackEvent('hubLookupFailed', {
                product: 'hub',
                lookupCategory: entity,
                lookupValue: value,
                lookupSource: 'invoices',
                customerId,
                analyticsId
              })
            )
          )
          const latestState = getState()

          deliveries = selectDeliveriesByCustomerDeliveryNumber(latestState, value, customerId)
        }
        // eslint-disable-next-line max-depth
        if (deliveries && deliveries.length > 0 && isDeliveriesWithLink(deliveries)) {
          dispatch(clearCurrentView())
          deliveries.forEach((delivery: Delivery) => {
            const {links} = delivery
            const invoiceId: string | undefined = links
              ? getInvoiceIdFromDeliveryLinks(links)
              : undefined
            if (invoiceId) {
              params = {
                ...params,
                [LookupEntityParam[LookupSelectItem.BOL]]: invoiceId,
                skip: 0
              }
              dispatch(fetchInvoicesAbstract(params))
            }
          })
        } else {
          trackEvent('hubLookupFailed', {
            product: 'hub',
            lookupCategory: entity,
            lookupValue: value,
            lookupSource: 'invoices',
            customerId,
            analyticsId
          })
          dispatch(clearCurrentView())
          dispatch(fetchInvoicesFailure())
        }
      } else {
        const {sortOrder, skip, limit} = selectInvoices(state)
        params = {
          ...params,
          sortedBy: `${sortOrder.key} ${sortOrder.asc ? 'asc' : 'desc'}`,
          [LookupEntityParam[entity]]: value,
          skip,
          limit: limit + 1 // download one more to decide whether to show "Load more" button
        }
        dispatch(fetchInvoicesAbstract(params, lookup, analyticsId))
      }
    } else {
      const {sortOrder, skip, limit} = selectInvoices(state)
      const {startDate, endDate} = selectDateFilter(state) || {}
      const {projectId} = selectProjectFilter(state) || {}
      const siteId = params.siteId || selectSiteFilter(state)?.siteId
      const {includesServicesOrSurcharges} = selectSurchargesOnlyFilter(state) || {}
      const {status} = selectOpenInvoicesFilter(state) || {}
      params = {
        ...params,
        sortedBy: `${sortOrder.key} ${sortOrder.asc ? 'asc' : 'desc'}`,
        projectId,
        siteId,
        startDate,
        endDate,
        skip,
        limit: limit + 1 // download one more to decide whether to show "Load more" button
      }
      if (includesServicesOrSurcharges) {
        params.includesServicesOrSurcharges = includesServicesOrSurcharges
      }
      if (status && typeof status === 'string') {
        params.status = status
      } else if (status && status.length > 0) {
        params.status = status.join()
      }
      dispatch(fetchInvoicesAbstract(params))
    }
  }

export const showThisWeekInvoices = (language: string) => (dispatch) => {
  const [startDate, endDate] = thisWeekDates(language)
  dispatch(clearInvoices())
  dispatch(
    setDateFilter({
      startDate: startDate.format(MOMENTJS_DATE_FORMAT),
      endDate: endDate.format(MOMENTJS_DATE_FORMAT)
    })
  )
  dispatch(push(InvoiceRoute))
}

export const showPrevMonthInvoices = (language: string, customer: Customer) => (dispatch) => {
  const [startDate, endDate] = prevMonthDates(language)
  dispatch(clearInvoices())
  dispatch(
    setDateFilter({
      startDate: startDate.format(MOMENTJS_DATE_FORMAT),
      endDate: endDate.format(MOMENTJS_DATE_FORMAT)
    })
  )
  dispatch(setGlobalCustomerFilter(customer))
  dispatch(push(InvoiceRoute))
}

export const showPrevQuarterInvoices = (language: string, customer: Customer) => (dispatch) => {
  const [startDate, endDate] = prevQuarterDates(language)
  dispatch(clearInvoices())
  dispatch(
    setDateFilter({
      startDate: startDate.format(MOMENTJS_DATE_FORMAT),
      endDate: endDate.format(MOMENTJS_DATE_FORMAT)
    })
  )
  dispatch(setGlobalCustomerFilter(customer))
  dispatch(push(InvoiceRoute))
}

export const bulkDownload = (args?: BulkDownloadArgs) => (dispatch, getState) => {
  const state: AppState = getState()
  const {customerId} = selectCustomerInvoiceFilter(state) || {}

  const criteria: InvoiceDocumentsExportCriteria = {
    customerId,
    invoiceIds: args?.invoiceIds || [],
    deliveries: args?.deliveryIds?.map((id) => ({invoiceId: id, filteredDeliveryIds: []})) || [],
    includePumpService: args?.includePumpService
  }

  criteria.deliveries = criteria.deliveries.concat(
    args?.surchargeItems.map((surchargeItem) => ({
      invoiceId: surchargeItem.invoiceId,
      filteredDeliveryIds: surchargeItem.deliveryIds
    })) || []
  )

  // include surcharge deliveries or invoice delivery
  const numberOfDeliveryFilesSelected = criteria.deliveries.reduce(
    (count, current) =>
      count + (current.filteredDeliveryIds.length > 0 ? current.filteredDeliveryIds.length : 1),
    0
  )

  trackEvent(args?.analytics.eventName as string, {
    ...args?.analytics,
    ...criteria,
    noOfFilesSelected: (criteria.invoiceIds?.length ?? 0) + numberOfDeliveryFilesSelected
  })
  const isCombined = args?.exportSettings?.combined
  const ext = isCombined ? ApiDownloadRequestFormats.pdf : ApiDownloadRequestFormats.zip
  return dispatch(
    sheetRequest({
      criteria,
      type: ApiDownloadRequestTypes.bulkInvoiceDeliveryDownload,
      format: ext,
      name: `tickets-${moment().format('YYYY-MM-DD-HH-mm-ss')}.${ext}`,
      shortName: `tickets-${moment().format('YYYY-MM-DD-HH-mm-ss')}`,
      ...args,
      analytics: {
        initiationSource: args?.analytics.initiationSource,
        jobId: args?.analytics.jobId,
        numberOfDaysSelected: args?.analytics.numberOfDaysSelected,
        exportCombined: isCombined
      },
      exportSettings: args?.exportSettings
    })
  )
}

function isDeliveriesWithLink(deliveries: Delivery[]): boolean {
  for (let i = 0; i < deliveries.length; i++) {
    const invoiceLink = deliveries[i].links?.find((link) => link.rel === 'invoices')
    if (invoiceLink) return true
  }
  return false
}
