// Generated by https://quicktype.io

import {BusinessLineTypes} from '../../../common/types'
import {Customer} from '../../../Organisms/Customers/Customers.types'

import {Delivery, LinkMediaType} from './Delivery.types'

export interface Invoice {
  /**
   * the customer of the invoice
   */
  customer?: Customer

  /**
   *  the customer who is being billed
   */
  billTo: BillToAddress
  // Not generated 👇
  deliveries?: any
  deliveriesLoading?: boolean
  /**
   * ISO 4217 currency code valid for entire invoice
   */
  invoiceCurrency: string
  /**
   * the posting date of the invoice as date only value
   */
  invoiceDate?: null | string
  /**
   * the creation date of the invoice as date only value
   */
  creationDate?: null | string
  /**
   * Unique Identifier for the order
   */
  invoiceId: string
  /**
   * The invoice net amount in cents.
   */
  invoiceNetValue: number
  /**
   * The invoice gross amount in cents.
   */
  invoiceGrossValue: number
   /**
   * The paid amount in cents. (for partially paid invoices)
   */
   paidAmount?: number
   /**
    * The remaining amount in cents. (for partially paid invoices)
    */
   remainingAmount?: number
  /**
   * Reference to Business value of the invoice number
   */
  invoiceNumber: string
  /**
   * Reference to FI number for Russia
   */
  customerInvoiceNumber?: string
  /**
   * The invoice tax amount in cents.
   */
  invoiceTaxValue: number
  lineItems: LineItem[]
  links: InvoiceLink[]
  /**
   * the payer of the invoice
   */
  payer: Payer
  /**
   * Status information of the invoice
   */
  status: InvoiceStatuses
  /**
   * describes the type of the invoice
   */
  type: InvoiceCategories
  /**
   * sales org id
   */
  orgUnitId?: string
  /**
   * business line enum
   */
  businessLine: BusinessLineTypes
  /**
   * Indicator for service or surcharges
   */
  servicesOrSurcharges?: ServicesOrSurcharges
  /** Breakdown Line items */
  materialItems: BreakdownLineItem[]
  surchargeItems: BreakdownLineItem[]
  serviceItems: BreakdownLineItem[]
  paymentTermDescription?: string
  isPaymentPending?: boolean
  isFinanceAccountingInvoice: boolean
}
export interface BillToAddress {
  billToId: string
  billToNumber: string
  billToName: string
  countryId: string
  state: string
  street: string
  postalCode: string
  city: string
}

export interface LineItem {
  /**
   * Customer reference text used by customer to identify the order, e.g. customer purchase
   * order reference
   */
  customerReference?: null | string
  /**
   * Reference to the position number of the row in the invoice
   */
  itemNumber: string
  grossAmount?: number
  links: LineItemLink[]
  /**
   * Description of the material of the order
   */
  materialDescription: string
  /**
   * Reference to business key of the material in the backend system
   */
  materialNumber: string
  /**
   * The net amount in cents of the invoice item
   */
  netAmount: number
  /**
   * quantity billed
   */
  quantity: number
  /**
   * The unit of measurement of the quantity order
   */
  quantityUom: string
  /**
   * The tax amount in cents of the invoice item
   */
  taxAmount: number
  /**
   * the net unit price of the item
   */
  unitPrice: number
  /**
   * Line item type
   */
  lineItemTypes: LineItemTypes[]
  /**
   * Contract number
   */
  contractNumber: string
  /**
   * Contract item
   */
  contractItem: string
  isPaymentPending?: boolean
  customerMaterialDescription?: string
  isTax?: boolean
  isCarbonSavingProduct?: boolean
}

export interface DescriptionWithRelatedLineItem {
  relatedLineItem: string
  description: string
}
export type BreakdownLineItem = Partial<LineItem> & DescriptionWithRelatedLineItem

export enum LineItemType {
  default = 'default',
  material = 'material',
  service = 'service',
  surcharge = 'surcharge',
  serviceOrSurcharge = 'serviceOrSurcharge'
}

export type LineItemTypes = keyof typeof LineItemType

export interface LineItemLink {
  /**
   * Link to the endpoint
   */
  href: string
  /**
   * media type
   */
  media?: null | string
  /**
   * Type of the relationship to the reference
   */
  rel?: RelToReferences
}

/**
 * Type of the relationship to the reference
 */
export enum PurpleRel {
  AccountingTransactions = 'accountingTransactions',
  Deliveries = 'deliveries',
  Orders = 'orders',
  Services = 'services'
}
export enum RelToReference {
  accountingTransactions = 'accountingTransactions',
  deliveries = 'deliveries',
  orders = 'orders',
  services = 'services',
  contracts = 'contracts',
  projects = 'projects',
  sites = 'sites',
  plants = 'plants'
}

export type RelToReferences = keyof typeof RelToReference
export interface InvoiceLink {
  /**
   * Link to the document
   */
  href: string
  /**
   * media type
   */
  media?: null | LinkMediaType
  /**
   * Type of the relationship to the reference
   */
  rel?: FluffyRel
  /**
   * indicating the type of document content
   */
  type?: LinkType | null
}

/**
 * Type of the relationship to the reference
 */
export enum FluffyRel {
  Documents = 'documents'
}

export enum LinkType {
  DeliveryNote = 'deliveryNote',
  Invoice = 'invoice',
  ProofOfDelivery = 'proofOfDelivery'
}

/**
 * the payer of the invoice
 */
export interface Payer {
  /**
   * Unique Identifier for the customer across backend systems
   */
  customerId: string
  /**
   * descriptive name of the customer, often equal to the company name
   */
  customerName: string
  /**
   * Reference to business related customer identifier
   */
  customerNumber?: null | string
  /**
   * country identifier
   */
  countryId?: string
  /**
   * street address
   */
  street?: string
  /**
   * postal code of the payer
   */
  postalCode?: string
  /**
   * city of the payer
   */
  city?: string
  /**
   * state of the payer
   */
  state?: string
}

/**
 * Status information of the invoice
 */
export enum Status {
  Cancelled = 'cancelled',
  Closed = 'closed',
  Open = 'open'
}
export enum InvoiceStatus {
  cancelled = 'cancelled',
  closed = 'closed',
  open = 'open',
  partiallyPaid = 'partiallyPaid'
}
export type InvoiceStatuses = keyof typeof InvoiceStatus

/**
 * descibes the type of the invoice
 */
export enum InvoiceType {
  CreditNote = 'creditNote',
  DebitNote = 'debitNote',
  Invoice = 'invoice',
  ManualBillig = 'manualBilling'
}
export enum InvoiceCategory {
  creditNote = 'creditNote',
  debitNote = 'debitNote',
  invoice = 'invoice',
  manualBilling = 'manualBilling'
}
export type InvoiceCategories = keyof typeof InvoiceCategory

export enum PaymentExcludeReason {
  Unknown = 'unknown',
  Closed = 'closed',
  PaymentPending = 'paymentPending',
  NotInSalesArea = 'notInSalesArea',
  CreditNote = 'creditNote',
  PartiallyPaid = 'partiallyPaid',
  ClearingLimitExceeded = 'clearingLimitExceeded'
}

export enum TotalAmountCalculationGroup {
  Excluded = 'excluded',
  Included = 'included',
  ClearingLimitExceeded = 'clearingLimitExceeded'
}

export enum ServicesOrSurcharge {
  ServicesOrSurchargesOnly = 'ServicesOrSurchargesOnly',
  Service = 'Service',
  MaterialOnly = 'MaterialOnly',
  MaterialAndServicesOrSurcharges = 'MaterialAndServicesOrSurcharges'
}

export type ServicesOrSurcharges = keyof typeof ServicesOrSurcharge

export interface InvoicesSumStateType {
  sum: number
  invoiceIds: string[]
  invoiceNumbers: string[]
}

export type InvoicesSumDispatch =
  | Record<'sum', number>
  | Record<'invoiceIds', string[]>
  | Record<'invoiceNumbers', string[]>

export const invoicesSumReducer = (
  state: InvoicesSumStateType,
  action: InvoicesSumDispatch
): InvoicesSumStateType => ({...state, ...action})

export const initialInvoicesSumState: InvoicesSumStateType = {
  sum: 0,
  invoiceIds: [],
  invoiceNumbers: []
}

export interface SearchInvoicesQueryParameters {
  customerId?: string
  startDate?: string
  endDate?: string
  invoiceNumber?: string
  orgUnitId?: string
  businessLine?: string
  projectId?: string
  siteId?: string
  includesServicesOrSurcharges?: boolean
  status?: string
  excludeZeroNetValues?: boolean
  payerId?: string
  sortedBy?: string
  limit?: number
  skip?: number
  expand?: string
  poNumber?: string
  excludeCancelledInvoices?: boolean
  invoiceId?: string
  orgUnitIds?: string
}

export interface InvoiceAnalytics {
  customerId: string
  customerName: string
  payerId?: string
  user_id: string
  country: string
  hasRoles: string[]
  isInternal: boolean
  startDate: string
  endDate: string
}

export interface FinanceAnalytics {
  userId: string | undefined
  userMail: string | null | undefined
  userCountry: string | null | undefined
  userRoles: any[] | undefined
  userIsInternal: boolean | undefined
  customerId: string | undefined
  customerName: string | undefined
  startDate?: string
  endDate?: string
}

export interface AsideToggleTypes {
  isCustomerInvoiceNumber: boolean
  showState: boolean
  isCustomerDeliveryNumber: boolean
  useTruckLicensePlate: boolean
  isInvoiceDetailsDisabled: (invoiceId: string, country?: string) => boolean
}

export interface AggregateCost {
  netAmount: number
  invoiceCurrency: string
  materialDescription: string
  isCarbonSavingProduct?: boolean
}
export interface NormalizedAggregate {
  [key: string]: AggregateCost
}

export type DeliveryWithRelatedLineItem =
  | {
      delivery: Delivery
      relatedLineItem: string
    }
  | undefined

export interface InvoiceDocumentsExportCriteria {
  customerId: string
  invoiceIds: string[] | undefined
  deliveries: {invoiceId: string; filteredDeliveryIds: string[]}[]
  includePumpService?: boolean
}

export interface BulkDownloadArgs {
  sendEmail: boolean
  url: string
  fullName: string
  email: string
  country: string
  analytics: any
  invoiceIds: string[]
  deliveryIds: string[]
  exportSettings: any
  surchargeItems: {invoiceId: string; deliveryIds: string[]}[]
  includePumpService?: boolean
}
