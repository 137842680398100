/* eslint-disable complexity */
import {Typography} from '@hconnect/uikit'
import {Grid} from '@material-ui/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CloseIcon from '@mui/icons-material/Close'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import {IconButton} from '@mui/material'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {PageNames} from '../../../common/constants'
import {DeliveryProgressBar} from '../../../Molecules/DeliveryProgressBar'
import {Divider} from '../../../Molecules/Divider/Divider'
import {DownloadAllDeliveryNotes} from '../../../Molecules/DownloadAllDeliveryNotes/DownloadAllDeliveryNotes'
import {ETADeliveryTrucks} from '../../../Molecules/ETADeliveryTrucks/ETADeliveryTrucks'
import StatusIcon from '../../../Molecules/StatusIcons'
import StatusTooltip from '../../../Molecules/StatusTooltip'
import {ViewAllDeliveries} from '../../../Molecules/ViewAllDeliveries'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {statusFromOrderStatus} from '../../../Organisms/Orders'
import {PermissionTypes, usePermissions} from '../../../Permissions'
import {Delivery} from '../../Finance/Invoices/Delivery.types'
import {FieldValue, LoadingNumberValue, QRCodeValue} from '../components'
import {OrderCancellationButton} from '../components/OrderCancellationButton'
import {useDeliveriesByOrderId} from '../hooks'
import {Order} from '../Order.types'
import {usePlants} from '../OrderDetails/hooks'
import {OrderSummary} from '../OrderDetails/OrderDetailsHeader'

import {useOrderDetailsSMStyles} from './OrderDetailsSMStyle'

type OrderDetailsSMProps = {
  deliveries?: Delivery[]
  orderHistoryDetails: JSX.Element
  order: Order
  expand: () => void
  close: () => void
  isMobile: boolean
  customerId: string
  analytics?: any
  bulkDownload: (
    deliveryIds: string[],
    orderIds: string[],
    ticketsCount: number,
    combined: boolean
  ) => void
}
export const OrderDetailsSM: React.FC<OrderDetailsSMProps> = ({
  orderHistoryDetails,
  expand,
  close,
  isMobile,
  order,
  customerId,
  bulkDownload
}) => {
  const {t} = useTranslation()
  const classes = useOrderDetailsSMStyles()
  const {getFeature} = useFeaturesState()
  const status = order && statusFromOrderStatus(order.orderStatus, order.shippingType)
  const {hasPermission} = usePermissions()
  const hasChangeOrderPermission = hasPermission(PermissionTypes.CHANGE_ORDERS)
  const isGroupedDeliveries = getFeature('OrderIntakeGroupedDeliveries')

  const isFilteringOutCancelledDeliveries =
    getFeature('ExcludeAllCancelledDeliveries') || getFeature('ExcludeNotLoadedCancelledDeliveries')
  const isPumpServiceIncluded = getFeature('IncludePumpService')
  const useHaulierView = getFeature('UseHaulierView')
  const {
    data: deliveries,
    error: deliveriesError,
    isFetching: deliveriesFetching
  } = useDeliveriesByOrderId(
    customerId,
    order?.orderId,
    isGroupedDeliveries,
    isFilteringOutCancelledDeliveries,
    order?.orderIds,
    isPumpServiceIncluded,
    useHaulierView
  )

  const {data: plant, isFetching: isPlantsFetching} = usePlants(
    order.businessLine,
    deliveries ? deliveries[0]?.plantName : null,
    deliveries ? deliveries[0]?.plantId : null
  )

  const [orderHistoryDetailsOpen, setOrderHistoryDetailsOpen] = useState(false)

  const hideCancelButtonStatuses = ['delivered', 'closed', 'paymentPending', 'rejected']
  const hideCancelButton = hideCancelButtonStatuses.includes(order.orderStatus)

  const qrCode = <QRCodeValue order={order} deliveries={deliveries} />
  // const totalNonServicesDeliveries = deliveries?.filter((delivery) => !delivery.isService).length

  return (
    <Box p={2} height="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{t('order.orderDetails.summary')}</Typography>
        <Box>
          {!isMobile && (
            <IconButton
              className={classes.icon}
              size="large"
              onClick={() => expand()}
              data-test-id="order-full-details"
            >
              <OpenInFullIcon />
            </IconButton>
          )}
          <IconButton
            className={classes.icon}
            size="large"
            onClick={() => close()}
            data-test-id="order-details-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Grid container>
        <Grid item xs={6}>
          <StatusTooltip
            title={t(`order.orderStatusLabel.tooltip.${status}`)}
            placement="top-start"
            data-test-id={`order-status-tooltip-${order.orderId}`}
          >
            <Box display="flex" alignItems="center" height="100%">
              <StatusIcon status={order.orderStatus} shippingType={order.shippingType} />
              <Box mx={0.5} />
              <Typography variant="h4">{t(`order.orderStatusLabel.${status}`)}</Typography>
            </Box>
          </StatusTooltip>
        </Grid>
        <Grid item xs={6} style={{marginTop: 8}}>
          <FieldValue
            dataTestId="shipping-type"
            label={t('order.orderDetails.shippingTypeLabel')}
            value={order.shippingType ? t(`order.orderDetails.${order.shippingType}`) : undefined}
            gutter
          />
        </Grid>
      </Grid>
      <OrderSummary
        order={order}
        plant={plant || undefined}
        isPlantsFetching={isPlantsFetching || deliveriesFetching}
        data-test-id="order-summary"
      />
      <LoadingNumberValue order={order} deliveries={deliveries} />
      {qrCode ? (
        <>
          <Divider />
          {qrCode}
        </>
      ) : null}
      <DeliveryProgressBar order={order} />
      <ETADeliveryTrucks t={t} order={order} pageName={PageNames.ORDERS} />
      <DownloadAllDeliveryNotes t={t} bulkDownload={bulkDownload} order={order} />
      <ViewAllDeliveries t={t} totalDeliveries={deliveries?.length || 0} expand={expand} />

      <Features name="OrderHistory">
        <>
          <Box mt={1} className={classes.section} data-test-id="order-history-details">
            <Typography>{t('order.orderDetails.orderHistoryDetails')}</Typography>
            <IconButton
              className={classNames(classes.icon)}
              onClick={() => setOrderHistoryDetailsOpen(!orderHistoryDetailsOpen)}
            >
              {orderHistoryDetailsOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </Box>
          <Divider />
          <Box>{orderHistoryDetailsOpen && orderHistoryDetails}</Box>
        </>
      </Features>
      <Features name="OrderIntakeOrderCancellation">
        {!hideCancelButton && hasChangeOrderPermission && (
          <Box pb={2} mt={4}>
            <OrderCancellationButton order={order} />
          </Box>
        )}
      </Features>
    </Box>
  )
}
